.content {
  flex-direction: row;
}

.mainContent {
  flex: 1;
}

.sidebar {
  margin-left: 36px;
  width: 200px;
}