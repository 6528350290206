.page {
  display: flex;
  flex-direction: column;
  width: 793px;
  min-height: 1120px;
  background: white;
  page-break-before: always;
  &:first-of-type {
    page-break-before: initial;
  }
}