.footer {
  display: flex;
  justify-content: stretch;
  align-items: end;
  position: relative;
  padding: 0 48px 48px;

  &>* {
    flex: 1;
    color: gray;
    font-size: 14px;
  }

  &>:nth-child(2) {
    display: flex;
    justify-content: center;
  }

  &>:nth-child(3) {
    font-size: 14px;
    text-align: right;
  }
}