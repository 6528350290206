.background {
  flex-direction: column;
  display: flex;
  align-items: center;

  @media not print {
    .a4 {
      margin-top: 24px;

      &:last-of-type {
        margin-bottom: 24px;
      }
    }
  }
}