.contentContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .pictureContainer {
    position: relative;
    img {
      height: 200px;
    }

    .imageAnnotation {
      position: absolute;
      left: 120%;
      top: 40%;
      display: flex;
      white-space: nowrap;
      img.arrow {
        height: 30px;
      }
      img.cherries {
        height: 20px;
      }
      span {
        position: absolute;
        top: -55px;
        left: -10px;
        transform: rotate(-25deg);
      }
    }
  }

  .name {
    margin-top: 32px;
  }

  .createdFor {
    margin-top: 0;
  }

  .attributeListContainer {

  }

  .attributeContainer {
    //border: black 2px solid;
    border-radius: 12px;
    margin-top: 48px;
    padding: 30px 60px;
    text-align: center;
    width: 500px;

    h4 {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h4:not(:first-of-type) {
      margin-top: 36px;
    }

    img {
      height: 30px;
      margin-right: 12px;
    }
  }
}

.bottomChevron {
  margin-bottom: -15px;
}