.sidebar {
  background: red;
  width: 200px;
}

.titleRow {
  display: flex;
  margin-top: 40px;

  h4, p{
    margin: 0;
  }

  p {
    margin-top: 8px;
  }

  img {
    margin-top: -2px;
    margin-right: 20px;
    height: 50px;
  }
}


.callToActionContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;

  .callToAction {
    display: flex;
    align-items: center;
    img {
      margin-left: 4px;
    }
  }

  ul {
    margin: 0;

    li {
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      align-items: center;
      justify-content: center;

      & > span:first-child {
        display: flex;
        color: darkgray;
        font-size: 16px;

        img {
          margin-right: 16px;
        }
      }

      & > span:last-child {
        margin-top: 12px;
        font-size: 18px;
      }
    }
  }
}

