.page {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.downloadContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  .downloadButton {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 22px 42px;

    .icon {
      font-size: 32px;
    }

    .filename {
      margin-top: 12px;
    }
  }
}

