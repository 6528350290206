.skillRow{
  margin-top: 8px;
  border: black 1px solid;
  padding: 14px;
  display: flex;
  cursor: pointer;
  position: relative;
  input {
  }
  span {
    flex: 1;
    display: flex;justify-content: center;
  }
}

.active {
  background: lightgray;
}