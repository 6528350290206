.page {
  display: flex;
  height: 100vh;
}

.wizard {
  width: 500px;
  box-shadow: -2px 1px 19px 0px rgba(0,0,0,0.75);
}

.cv {
  flex: 1;
  overflow: scroll;
    width: 100vw;
    background: gray;
    display: flex;
    flex-direction: column;
    align-items: center;

  .a4 {
    margin-top: 24px;

    &:last-of-type {
      margin-bottom: 24px;
    }
  }
}