.page {
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
}

.mainContent {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
}

.container {
  display: flex;
  max-width: 600px;
  width: 100%;
  flex: 1;
}

.carousel {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.footer {
  width: 100%;
  border-top: black 1px solid;
  justify-content: center;
  display: flex;

  .footerContent {
    padding: 16px;
    display: flex;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
  }
}