@import "../../node_modules/normalize.css/normalize.css";
@import "../../node_modules/react-responsive-carousel/lib/styles/carousel.min.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Nunito&display=swap");
@import "_colors.scss";
@import "_button.scss";

body {
  margin: 0;
  font-family: "Nunito", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

.carousel {
  height: 100%;
  .slider-wrapper {
    height: 100%;
    .slider {
      height: 100%;
      .slide {
        text-align: initial;
        &>div {
          height: 100%;
        }
      }
    }
  }
}